<template>
  <div>
    <div id="outPassenger" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-24">PASSENGER</div>
        <div class="b-numPassenger">
          <span class="icon I-passengers"></span>
          <div class="T-size-20 SemiBold">
            {{ ArrlistPassenger.length }} Passengers
          </div>
        </div>
      </div>

      <div class="box-S4">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Passenger List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="wrap-passenger">
                <div class="box-4" v-for="item in ArrlistPassenger" :key="item.IdPass">
                  <div class="B-passenger TF" @click="SelectPassenger(item)">
                    <div class="b-person">
                      <div class="box-S1 fix-header">
                        <div v-if="
                          item.u_imageurl == undefined ||
                          item.u_imageurl == null
                        "></div>
                        <div class="B-display person1" v-else :style="{
                          backgroundImage:
                            'url(' + item.u_imageurl + ') !important',
                        }"></div>
                      </div>
                      <div class="box-S4">
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <!-- <div class="t-company T-size-12">
                            <span v-if="item.c_name != undefined">{{
                                item.c_name
                            }}</span><span v-else>No Company</span>&nbsp;<span v-if="item.p_rfid"
                              style="color: #800080">{{ item.p_rfid }}</span>
                          </div> -->
                          <div class="t-company T-size-12">
                            Health Screening Form : {{ item.mhf_status }}
                          </div>
                          <div class="t-company T-size-12">
                            COVID-19 Form : {{ item.mcf_status }}
                          </div>
                          <div class="t-company T-size-12">
                            Alcohol and Urine Form : {{ item.maf_status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="ArrlistPassenger.length == 0">
                  <div class="b-nodata">
                    <div class="t-nodata">Please Select Passengers</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <v-dialog v-model="dialogConfirmmPersonal" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog confirm">
              <div class="t-title">Confirm Personal Information</div>
              <div class="t-des">
                <div>Are you</div>
                <div>
                  {{ this.user.FullName }}
                </div>
              </div>
              <div class="box-S4"></div>
            </div>
            <div class="box-btn">
              <v-btn @click="dialogConfirmmPersonal = false" class="theme-btn-even btn-cancel Bsize100" text>
                <span class="T-size-18">CANCEL</span>
              </v-btn>
              <v-btn @click="CreateEForm()" class="theme-btn-even btn-save Bsize100" text>
                <span class="T-size-18">CONFIRM</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
export default {
  data: () => ({
    userdata: "",
    ArrlistPassenger: [],
    panel: [0],
    user: { IdPass: 0, FullName: "" },
    dialogConfirmmPersonal: false,
  }),
  // props: ["TypePassenger"],
  //Edit
  props: ["flightId"],
  components: {},
  async mounted() {
    this.DataPassengerFromDB(this.flightId);
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async DataPassengerFromDB(idflight) {
      try {
        var q = {};
        q.f_id = idflight;
        q.pt_id = 1;
        q.retire = 0;
        var res = await feathersClientUOA
          .service("viewcheckinpassenger-2")
          .find({ query: q });
        this.itemsDataPassenger = res.data;
        this.ArrlistPassenger = [];
        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["IdPass"] = element.p_id;
          datachoosepassenger["FullName"] =
            element.u_firstname + " " + element.u_lastname;
          datachoosepassenger["u_imageurl"] = element.u_imageurl;

          datachoosepassenger["mhf_status"] = "No Data";
          datachoosepassenger["mcf_status"] = "No Data";
          datachoosepassenger["maf_status"] = "No Data";
          if (element.mhf_status != null) {
            datachoosepassenger["mhf_status"] = element.mhf_status;
          }
          if (element.mcf_status != null) {
            datachoosepassenger["mcf_status"] = element.mcf_status;
          }
          if (element.maf_status != null) {
            datachoosepassenger["maf_status"] = element.maf_status;
          }

          this.ArrlistPassenger.push(datachoosepassenger);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async SelectPassenger(data) {
      if (
        data.mhf_status == "No Data" ||
        data.mhf_status == null ||
        data.mcf_status == "No Data" ||
        data.mcf_status == null ||
        data.maf_status == "No Data" ||
        data.maf_status == null
      ) {
        this.user = { IdPass: data.IdPass, FullName: data.FullName };
        this.dialogConfirmmPersonal = true;
      }
    },
    async CreateEForm() {
      var q0 = {};
      q0.p_id = this.user.IdPass;
      var res0 = await feathersClientUOA
        .service("mubadala-test-record-form")
        .find({ query: q0 });
      if(res0.total == 0){
        var dataAdd = {
          p_id: this.user.IdPass
        };
        await feathersClientUOA.service("mubadala-test-record-form").create(dataAdd);
      }

      var q1 = {};
      q1.p_id = this.user.IdPass;
      var res1 = await feathersClientUOA
        .service("mubadala-health-form-2")
        .find({ query: q1 });
      if (res1.total == 0) {
        var dataEForm1 = {
          p_id: this.user.IdPass,
          mhf_status: "No Data",
        };
        await feathersClientUOA
          .service("mubadala-health-form-2")
          .create(dataEForm1);
      }

      var q2 = {};
      q2.p_id = this.user.IdPass;
      var res2 = await feathersClientUOA
        .service("mubadala-covid-form-2")
        .find({ query: q2 });
      if (res2.total == 0) {
        var dataEForm2 = {
          p_id: this.user.IdPass,
          mcf_status: "No Data",
        };
        await feathersClientUOA
          .service("mubadala-covid-form-2")
          .create(dataEForm2);
      }

      var q3 = {};
      q3.p_id = this.user.IdPass;
      var res3 = await feathersClientUOA
        .service("mubadala-alcohol-form-2")
        .find({ query: q3 });
      if (res3.total == 0) {
        var dataEForm3 = {
          p_id: this.user.IdPass,
          maf_status: "No Data",
        };
        await feathersClientUOA
          .service("mubadala-alcohol-form-2")
          .create(dataEForm3);
      }


      var q11 = {};
      q11.p_id = this.user.IdPass;
      var res11 = await feathersClientUOA
        .service("mubadala-health-form-2")
        .find({ query: q11 });
      if (res11.data.filter((x) => x.p_id == this.user.IdPass)[0].mhf_status == "No Data") {
        this.$router.push({
          path: `/mubadalaforcustomereform1/${this.user.IdPass}`,
        });
        return;
      }

      var q22 = {};
      q22.p_id = this.user.IdPass;
      var res22 = await feathersClientUOA
        .service("mubadala-covid-form-2")
        .find({ query: q22 });
      if (res22.data.filter((x) => x.p_id == this.user.IdPass)[0].mcf_status == "No Data") {
        this.$router.push({
          path: `/mubadalaforcustomereform2/${this.user.IdPass}`,
        });
        return;
      }

      var q33 = {};
      q33.p_id = this.user.IdPass;
      var res33 = await feathersClientUOA
        .service("mubadala-alcohol-form-2")
        .find({ query: q22 });
      if (res33.data.filter((x) => x.p_id == this.user.IdPass)[0].maf_status == "No Data") {
        this.$router.push({
          path: `/mubadalaforcustomereform3/${this.user.IdPass}`,
        });
        return;
      }



      //Check Signature
      var qs = {};
      qs.p_id = this.user.IdPass;
      qs.maf_pass_signature = null;
      var resSignature = await feathersClientUOA
        .service("mubadala-alcohol-form-2")
        .find({ query: qs });
      if (resSignature.total != 0) {
        this.$router.push({
          path: `/mubadalaforcustomereformsignature/${this.user.IdPass}`,
        });
        return;
      }
    },
  },
};
</script>

<style scrop>
.center {
  text-align: center;
}

.theme-btn-even.offload {
  width: 100%;
  margin: 10px 0;
}
</style>